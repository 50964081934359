import React from "react";
import { LayoutProvider } from "../../components/layout";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import { useViewPortSize } from "../../hooks";
import { CustomerServicePage } from "../../components/sections";
import { stackStyled } from "../../theme";

export interface IDataCards {
  id: number;
  title: string;
  action: string;
  href: string;
  icon: {
    url: string;
    ext: string;
  };
}

interface IData {
  strapiCustomerService: {
    title: string;
    description: string;
    cards: Array<IDataCards>;
  };
}

const query = graphql`
  query CustomerService {
    strapiCustomerService {
      title
      description
      cards {
        id
        title
        action
        href
        icon {
          url
          ext
        }
      }
    }
  }
`;

const MainWrapper = stackStyled(
  "div",
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: "flex",
    width: props.isMobile ? "95%" : props.isTablet ? "90%" : "100%",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    overflowX: "hidden",
    textAlign: "center",
    minHeight: "auto",
  })
);

const InnerContainer = stackStyled('div', ({isMobile}:{isMobile: boolean}) => ({
  margin: isMobile ? '0 30px' : '0 110px',
}))

const CustomerService: React.FC<PageProps> = (props: PageProps) => {
  const data = useStaticQuery<IData>(query);
  const [isMobile, isTablet] = useViewPortSize();

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet}>
        <InnerContainer isMobile={isMobile || isTablet}>
          <CustomerServicePage
            title={data.strapiCustomerService.title}
            description={data.strapiCustomerService.description}
            cards={data.strapiCustomerService.cards}
          />
        </InnerContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default CustomerService;
